<template lang="html">
    <main-layout>
        <template #content>
            <profile-form-layout
                :show-modal="showModal"
                :title="'Nickname'"
                @close-modal="showModal=false"
                @confirm="confirm"
            >
                <template #headerIcon>
                    <account-multiple />
                </template>
                <template #form>
                    <div class="wizard-general">
                        <form
                            class="wizard-general__form wizard__form"
                            @submit.prevent="submit"
                        >
                            <div class="wizard-general__field wizard-general__field-nickname">
                                <form-input
                                    v-model="nickname"
                                    legend="Nickname"
                                    :field.sync="nickname"
                                    :errors="errorMessageNm"
                                    :is-valid="validNickname"
                                    @input.native="checkNikname"
                                    @blur.native="checkNikname"
                                />
                            </div>
                        </form>
                    </div>
                </template>
            </profile-form-layout>
        </template>
    </main-layout>
</template>

<script>
import { mapWriterFields } from '@/store/modules/writer'

import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus';
import MainLayout from '@/layouts/MainLayout';
import ProfileFormLayout from '@/layouts/profile/ProfileFormLayout';
import {
    ACTION_WRITER_CHECK_NIKNAME,
    ACTION_WRITER_UPDATE_NICKNAME
} from '@/store/modules/writer/action-types'

const { mapActions: mapWriterActions } = createNamespacedHelpers('writer')

export default {
    name: 'Index',
    components: {
        MainLayout,
        ProfileFormLayout
    },
    metaInfo: {
        title: 'Nickname'
    },
    data() {
        return {
            showModal: false,
            validNickname: true,
            errorMessageNm: ''
        }
    },
    computed: {
        ...mapWriterFields([
            'writer_data',
            'writer_data.nickname'
        ])
    },
    mounted() {
        eventBus.$on('saveSettings', () => {
            this.submit()
        })
    },
    beforeDestroy() {
        eventBus.$off('saveSettings')
    },
    methods: {
        ...mapWriterActions([
            ACTION_WRITER_CHECK_NIKNAME,
            ACTION_WRITER_UPDATE_NICKNAME
        ]),
        async checkNikname() {
            try {
                await this[ACTION_WRITER_CHECK_NIKNAME]({ nickname: this.nickname })
                this.isValid = true
                this.errorMessage = ''
            } catch (error) {
                this.isValid = false
                this.errorMessage = error
                eventBus.$emit('showSnackBar', error, 'error');
            }
        },
        async confirm() {
            try {
                await this[ACTION_WRITER_UPDATE_NICKNAME]({ nickname: this.nickname })
                eventBus.$emit('showSnackBar', 'Your nickname has been changed', 'success');
                this.$nextTick(() => { this.$router.push({ name: 'profile' }) })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        },
        async submit() {
            await this.checkNikname()
            await this.confirm()
        }
    }
}
</script>

<style lang="scss">
    .wizard-general {
        &__field {
            margin-bottom: 20px;
            .form-select__input {
                padding-left: 10px;
            }
        }
    }
    .wizard-general__field-nickname{
        margin-bottom: 30px !important;
    }
</style>
